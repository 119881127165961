<div class="container">
  <h1 class="d-flex align-items-center">
    <a routerLink="/settings" class="btn p-0 me-3 subtitle d-lg-none"><app-icon icon="left"/></a>
    {{ 'Membership' | translate }}
  </h1>

  <h4 translate>Manage subscription</h4>

  <div class="form-block-lg" [ngClass]="{'m-width-none': subscriptionState == 2}">
    <p class="subheader small" *ngIf="!subscriptionState" innerHTML="{{ 'Page.Home.thanks' | translate | nl2br }}"></p>

    <div class="container page-content" *ngIf="loading">
      <img width="50" height="50" src="/assets/img/loader.gif" alt=""/>
    </div>

    <div class="form-block" [ngClass]="{'m-width-none': subscriptionState == 2}">

      <ng-container *ngIf="subscriptionData && !subscriptionState">
        <div class="panel-info mb-2">
          <div *ngIf="subscriptionData.name">
            <p class="subtitle">{{ 'Subscription type' | translate }}</p>
            {{ subscriptionData.name }}
          </div>

          <ng-container *ngIf="subscriptionData.since">
            <hr>
            <div>
              <p class="subtitle">{{ 'Member since' | translate }}</p>
              {{ subscriptionData.since }}
            </div>
          </ng-container>

          <ng-container *ngIf="subscriptionData.renewal">
            <hr>
            <div>
              <p class="subtitle">{{ 'Renewal date' | translate }}</p>
              {{ subscriptionData.renewal }}
            </div>
          </ng-container>

          <ng-container *ngIf="subscriptionData.price">
            <hr>
            <div>
              <p class="subtitle">{{ 'Price' | translate }}</p>
              {{ subscriptionData.price }}
            </div>
          </ng-container>

          <ng-container *ngIf="subscriptionData.via">
            <hr>
            <div>
              <p class="subtitle">{{ 'Subscription via' | translate }}</p>
              {{ subscriptionData.via }}
            </div>
          </ng-container>
        </div>

        <!--        <p *ngIf="managerLink">
                  <a href="{{ managerLink }}" class="btn btn-primary w-100">{{ 'Page.Home.manager' | translate }}</a>
                </p>-->
        <!--<p class="subtitle mb-3">{{ 'You can manage your subscription in settings' | translate }}</p>-->

        <p *ngIf="subscriptionData.cancelable">
          <button class="btn btn-secondary btn-marked w-100" (click)="onChangeSubscriptionState(1)">{{ 'Cancel my subscription' | translate }}</button>
        </p>
      </ng-container>

      <ng-container *ngIf="subscriptionState">
        <p *ngIf="isBack && !toOffer && type != 'offer'">
          <button class="btn btn-secondary btn-back" translate (click)="onChangeSubscriptionState(-1)">
            <svg-icon src="/assets/img/icons/left.svg" class="i"></svg-icon>Back
          </button>
        </p>

        <ng-container [ngSwitch]="subscriptionState">
          <ng-container *ngSwitchCase="1">
            <p>
              <svg-icon src="/assets/img/paywall/subscription_but_why.svg"></svg-icon>
            </p>

            <h2 class="lg" translate>But why?</h2>

            <div class="mb-2">
              <label class="btn btn-secondary btn-checkbox btn-border w-100" [ngClass]="{ 'active': whyCancel == whyCancelEnum.time }">
                <span class="form-check">
                  <span class="form-check-label small" translate>I don't use the app</span>
                  <input type="radio" name="language" class="form-check-input" value="{{ whyCancelEnum.time }}" [attr.checked]="whyCancel == whyCancelEnum.time ? 'checked' : null" (change)="whyCancel = whyCancelEnum.time">
                </span>
              </label>

              <label class="btn btn-secondary btn-checkbox btn-border w-100" [ngClass]="{ 'active': whyCancel == whyCancelEnum.price }">
                <span class="form-check">
                  <span class="form-check-label small" translate>It costs too much</span>
                  <input type="radio" name="language" class="form-check-input" value="{{ whyCancelEnum.price }}" [attr.checked]="whyCancel == whyCancelEnum.price ? 'checked' : null" (change)="whyCancel = whyCancelEnum.price">
                </span>
              </label>

              <label class="btn btn-secondary btn-checkbox btn-border w-100" [ngClass]="{ 'active': whyCancel == whyCancelEnum.other }">
                <span class="form-check">
                  <span class="form-check-label small" translate>Other</span>
                  <input type="radio" name="language" class="form-check-input" value="{{ whyCancelEnum.other }}" [attr.checked]="whyCancel == whyCancelEnum.other ? 'checked' : null" (change)="whyCancel = whyCancelEnum.other">
                </span>
              </label>
              <p *ngIf="whyCancel == whyCancelEnum.other">
                <textarea rows="4" class="form-control" style="height: auto;" placeholder="{{ 'Describe the reason...' | translate }}" (input)="cancelComment = $event.target.value"></textarea>
              </p>
            </div>

            <button *ngIf="whyCancel" class="btn btn-secondary btn-marked w-100" (click)="onCancelSurvey()">{{ 'Cancel my subscription' | translate }}</button>
          </ng-container>

          <ng-container *ngSwitchCase="2">
            <h2 class="lg" style="padding-top: 10px" innerHTML="{{ 'What you get with Vocal Image' | translate }}"></h2>

            <div class="row align-items-center info-1">
              <div class="col-md">
                <div class="icon">
                  <svg-icon src="/assets/img/icons/education.svg"></svg-icon>
                </div>
                <div>
                  <p translate>Unlimited Access</p>
                  <p class="note" translate>Get all courses, trainings, and features</p>
                </div>
              </div>
              <div class="col-md">
                <div class="icon">
                  <svg-icon src="/assets/img/icons/podcast.svg"></svg-icon>
                </div>
                <div>
                  <p translate>Voice Analyses</p>
                  <p class="note" translate>Learn all parameters of your voice</p>
                </div>
              </div>
              <div class="col-md">
                <div class="icon">
                  <svg-icon src="/assets/img/icons/diary.svg"></svg-icon>
                </div>
                <div>
                  <p translate>Track the Progress</p>
                  <p class="note" translate>See the changes with tailored charts</p>
                </div>
              </div>
            </div>

            <div class="d-lg-flex gap-3 comments">
              <div *ngFor="let comment of commentList; index as i" class="panel-info gap-1 mb-4">
                <div class="row-gap">
                  <img src="/assets/img/face/{{comment.avatar}}" alt="ava" width="32" height="32" class="ava" />
                  <div class="rates">
                    <svg-icon *ngFor="let i of [1,2,3,4,5]" src="/assets/img/icons/star.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                  </div>
                </div>
                <div class="note">{{ comment.date }}</div>
                <div>{{ comment.message }}</div>
              </div>
            </div>

            <div class="form-block">
              <p>
                <button class="btn btn-secondary w-100" (click)="onChangeSubscriptionState(1)" translate>Continue with cancellation</button>
              </p>

              <p>
                <button class="btn btn-primary w-100" (click)="subscriptionState = 0" translate>Keep subscription</button>
              </p>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="3">
            <ng-container *ngIf="toOffer; else offerContainer">
              <h2 class="lg" translate>Great deal! 🎉</h2>
              <p class="my">
                <svg-icon src="/assets/img/paywall/subscription_great_deal.svg"></svg-icon>
              </p>
              <p translate>Your special offer has been applied!</p>
            </ng-container>

            <ng-template #offerContainer>
              <ng-container *ngIf="offerData">
                <p class="my">
                  <svg-icon src="/assets/img/paywall/e_course_end.svg"></svg-icon>
                </p>
                <div class="text-center">
                  <p class="mb-1">
                    <s translate [translateParams]="{value: offerData.currencySymbol + (offerData.standardPrice || offerData.price), period: offerData.period}">Original Price</s>
                  </p>
                  <h3 class="gold" style="text-transform: uppercase" translate [translateParams]="{value: offerData.currencySymbol + offerData.fullPrice, period: offerData.period}">Your price only</h3>
                </div>

                <div class="panel-info my">
                  <strong *ngIf="offerData.promo">{{offerData.promo}}</strong>
                  <div class="note-2" translate>Keep access to Vocal Image’s personalized voice training sessions and expert content. Unleash the full potential of your voice!</div>
                </div>

                <p *ngIf="type != 'offer'">
                  <button class="btn btn-secondary w-100" (click)="onCancelSubscription()" translate>Continue with cancellation</button>
                </p>

                <p>
                  <a href="{{offerData.link}}" class="btn btn-primary w-100" translate>Get Special Offer</a>
                </p>
              </ng-container>
            </ng-template>
          </ng-container>

          <ng-container *ngSwitchCase="4">
            <ng-container *ngIf="!loading">
              <h2 class="lg" translate>See you soon!</h2>
              <p class="my">
                <svg-icon src="/assets/img/paywall/subscription_see_you_soon.svg"></svg-icon>
              </p>
              <p translate>We have cancelled your subscription, so there will be no further payment.</p>
              <p *ngIf="subscriptionData.renewal" innerHTML="{{ 'seeYou' | translate:{value: subscriptionData.renewal} }}"></p>
            </ng-container>
          </ng-container>

        </ng-container>
      </ng-container>

    </div>

  </div>

  <div *ngIf="error" class="alert alert-danger" [innerHTML]="error | translate | nl2br"></div>
</div>
